import React, { useCallback, useContext, useEffect, useState } from "react";
import ButtonComponent from "../../../commonComponents/button";
import { localText } from "../../../../constants/text";
import InspectionSmallHeader from "../../../commonComponents/inspectionHeader";
import { AppContext } from "../../../../contexts/app.context";
import { InspectionContext } from "../../../../contexts/inspection.context";
import { CONSTANTS } from "../../../../shared/constants/constants";
import SliderComponent from "../../../commonComponents/slider";
import addIcon from "../../../../assets//images/add.png";

export default function CameraVideo({ from }) {
  const { setHideHeaderFooter } = useContext(AppContext);
  const {
    setCameraMode,
    inspectionToBeSent,
    instructionsContent,
    setSubStepMode,
    selectedNameForMedia,
  } = useContext(InspectionContext);
  const [pickMediaFrom] = useState(from);
  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const [mediaAssets, setMediaAssets] = useState([]);

  const getMediaAssets = useCallback(() => {
    if (inspectionToBeSent[pickMediaFrom].length) {
      const data = inspectionToBeSent[pickMediaFrom];
      if (pickMediaFrom === CONSTANTS.ADDITIONAL_MEDIA) {
        setEnableSaveButton(true);
        return data;
      }
      const [filteredData] = inspectionToBeSent[pickMediaFrom].filter(
        (item) => item.name === selectedNameForMedia
      );
      if (
        typeof filteredData !== "undefined" &&
        filteredData.assets.length ===
          filteredData.min_image + filteredData.min_video
      ) {
        setEnableSaveButton(true);
      }
      return typeof filteredData !== "undefined" ? filteredData.assets : [];
    }
    return [];
  }, [inspectionToBeSent, pickMediaFrom, selectedNameForMedia]);

  useEffect(() => {
    setMediaAssets(getMediaAssets());
  }, [pickMediaFrom, inspectionToBeSent, getMediaAssets]);

  const renderMediaHeader = () => {
    const min_image = instructionsContent.min_image || "";
    const min_video = instructionsContent.min_video || "";
    return `${min_image} ${CONSTANTS.PHOTOS} ${min_video} ${CONSTANTS.VIDEOS}`;
  };

  return (
    <>
      <InspectionSmallHeader
        title={selectedNameForMedia}
        goBack={() => setSubStepMode(null)}
      />
      <div style={{ textAlign: "center" }}>
        <div className="mt-20">{CONSTANTS.INSTRUCTIONS}</div>
        <div className="mt-20">
          {instructionsContent.instructions || instructionsContent.comment}
        </div>

        <div className="photo-btn">{renderMediaHeader()}</div>
        <div>
          <SliderComponent data={mediaAssets} from={pickMediaFrom} />
        </div>
        <div className="camera-video-btn-wrap">
          <div
            className="mar-v-30"
            onClick={() => {
              setCameraMode(CONSTANTS.CAMERA);
              setHideHeaderFooter(true);
            }}
          >
            <img src={addIcon} />
            <div className="add-asset-text">{localText.photo}</div>
          </div>
          <div
            className="mar-v-30"
            onClick={() => {
              setCameraMode(CONSTANTS.VIDEO);
              setHideHeaderFooter(true);
            }}
          >
            <img src={addIcon} />
            <div className="add-asset-text">{localText.video}</div>
          </div>
        </div>
        <ButtonComponent
          className="button-contained"
          title={localText.save}
          onClick={() => {
            setSubStepMode(null);
          }}
          disabled={!enableSaveButton}
          style={{
            opacity: enableSaveButton ? 1 : CONSTANTS.DISABLED_OPACITY,
          }}
        />
      </div>
    </>
  );
}
