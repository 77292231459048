import React, { useEffect, useState } from "react";
import Success from "../success";
import Lottie from "lottie-react";
import { appAnimations } from "../../../../constants/image";
import { localText } from "../../../../constants/text";

export default function Confirmation() {
  const [closeMessage, setClosemessage] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setClosemessage(true);
    }, 500);
  }, []);
  return (
    <>
      {!closeMessage ? (
        <div className="submit-page-style">
          <div>{localText.oneMoment}</div>
          <div>{localText.yourInspection}</div>
          <Lottie
            className="animation"
            animationData={appAnimations.loader}
            loop={true}
          />
        </div>
      ) : (
        <>
          <Success />
        </>
      )}
    </>
  );
}
