import { createContext } from "react";
import { useApp } from "../hooks/useApp";

const AppContext = createContext({});

AppContext.displayName = "App";

const AppProvider = ({ children }) => {
  const {
    inspection,
    store,
    clientLogo,
    isDataLoaded,
    acceptFraudNotice,
    setAcceptFraudNotice,
    hideHeaderFooter,
    setHideHeaderFooter,
    inspectionIdLocal,
  } = useApp();
  const context = {
    inspection,
    store,
    clientLogo,
    isDataLoaded,
    acceptFraudNotice,
    setAcceptFraudNotice,
    hideHeaderFooter,
    setHideHeaderFooter,
    inspectionIdLocal,
  };

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};

export { AppContext, AppProvider };
