import React, { useContext } from "react";
import addIcon from "../../../../assets/images/addicon.png";
import ButtonComponent from "../../../commonComponents/button";
import { localText } from "../../../../constants/text";
import InspectionSmallHeader from "../../../commonComponents/inspectionHeader";
import SliderComponent from "../../../commonComponents/slider";
import { InspectionContext } from "../../../../contexts/inspection.context";
import { AppContext } from "../../../../contexts/app.context";
import { CONSTANTS } from "../../../../shared/constants/constants";

export default function Photo(props) {
  const {
    inspectionToBeSent,
    setSubStepMode,
    setCameraMode,
    instructionsContent,
    selectedNameForMedia,
  } = useContext(InspectionContext);
  const { setHideHeaderFooter } = useContext(AppContext);
  let dataToSend = [];
  inspectionToBeSent?.[CONSTANTS.REQUESTED_MEDIA]?.filter(
    (item, key) =>
      item.name === selectedNameForMedia && dataToSend.push(...item.assets)
  );

  return (
    <>
      <InspectionSmallHeader
        title={selectedNameForMedia}
        goBack={() => setSubStepMode(null)}
      />
      <div style={{ textAlign: "center" }}>
        <div className="mt-20">{CONSTANTS.INSTRUCTIONS}</div>
        <div className="mt-10">
          {instructionsContent.instructions || instructionsContent.comment}
        </div>
        <div className="photo-btn">
          {instructionsContent.min_image} {localText.photo}
        </div>
        <div>
          <SliderComponent data={dataToSend} from={CONSTANTS.REQUESTED_MEDIA} />
        </div>
        <div
          className="mar-v-30"
          onClick={() => {
            setCameraMode(CONSTANTS.CAMERA);
            setHideHeaderFooter(true);
          }}
        >
          <img src={addIcon} style={{ height: 30, width: 30 }} alt="add" />
          <div style={{ color: "#68758B" }}>{localText.photo}</div>
        </div>
        <ButtonComponent
          className="button-contained"
          title={localText.save}
          disabled={dataToSend.length < instructionsContent.min_image}
          onClick={() => {
            setSubStepMode(null);
          }}
          style={{
            opacity:
              dataToSend.length >= instructionsContent.min_image ? 1 : 0.5,
          }}
        />
      </div>
    </>
  );
}
