import { CONSTANTS } from "./constants/constants";

export const isObjEmpty = (obj = {}) => {
  return Object.keys(obj).length === 0;
};
export const isArrayEmpty = (arr = []) => {
  return (Array.isArray(arr) && arr.length) === 0 ? true : false;
};

export const validateRequestedForm = ({ inspection, inspectionToBeSent }) => {
  const totalMediaToBeUploaded = addMinImageAndVideo(
    inspection.supporting_docs
  );
  const minMediaBeingUploaded = getMinMediaBeingUploaded({
    datasets: inspectionToBeSent[CONSTANTS.REQUESTED_MEDIA],
  });
  if (minMediaBeingUploaded === totalMediaToBeUploaded) {
    return true;
  }
  return false;
};

const getMinMediaBeingUploaded = ({ datasets }) => {
  const uploadedSoFar = [];
  datasets.forEach((item) => {
    if (item.assets.length >= item.min_image + item.min_video) {
      uploadedSoFar.push(item.min_image + item.min_video);
    }
  });
  return uploadedSoFar.length ? uploadedSoFar.reduce((a, b) => a + b) : 0;
};

export const addMinImageAndVideo = (data) => {
  return data.reduce((a, b) => {
    return a + b.min_image + b.min_video;
  }, 0);
};

export const readTimestamp = (timestamp) => {
  const now = new Date();
  let time = "";
  const date = new Date(timestamp);
  const diff = Math.abs(now - date);
  if (diff < 60 * 60 * 1000) {
    time = "< 1 hr";
  } else if (diff >= 2 * 24 * 60 * 60 * 1000) {
    time = Math.floor(diff / (24 * 60 * 60 * 1000)) + " days";
  } else {
    time = Math.floor(diff / (60 * 60 * 1000)) + " hrs";
  }
  return time + " ago";
};

export function deleteFilesByName(array, fileToRemove) {
  if (isArrayEmpty(array)) return [];
  return array.filter((element) => {
    if (element.filename === fileToRemove) {
      return false;
    }
    if (element.assets) {
      element.assets = deleteFilesByName(element.assets, fileToRemove);
    }
    return true;
  });
}

export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const getAllSupportedMimeTypes = (...mediaTypes) => {
  if (!mediaTypes.length) mediaTypes.push(...["video", "audio"]);
  const FILE_EXTENSIONS = ["webm", "ogg", "mp4", "x-matroska"];
  const CODECS = [
    "vp9",
    "vp9.0",
    "vp8",
    "vp8.0",
    "avc1",
    "av1",
    "h265",
    "h.265",
    "h264",
    "h.264",
    "opus",
  ];

  return [
    ...new Set(
      FILE_EXTENSIONS.flatMap((ext) =>
        CODECS.flatMap((codec) =>
          mediaTypes.flatMap((mediaType) => [
            `${mediaType}/${ext};codecs:${codec}`,
            `${mediaType}/${ext};codecs=${codec}`,
            `${mediaType}/${ext};codecs:${codec.toUpperCase()}`,
            `${mediaType}/${ext};codecs=${codec.toUpperCase()}`,
            `${mediaType}/${ext}`,
          ])
        )
      )
    ),
  ].filter((variation) => MediaRecorder.isTypeSupported(variation));
};

export const checkIfValidBase64 = (str) => {
  var base64regex =
    /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  return base64regex.test(str);
};
