import { request } from "../../core/networkRequests";
import { METHOD_TYPES } from "../../../shared/constants/method-types";
import { ENDPOINTS } from "../../../shared/constants/endpoints";

export const postMedia = async (fileContent, reqNumber, type) => {
  const fileName = type === "video" ? "file.mp4" : "file.png";
  const fileType = type === "video" ? "video/mp4" : "image/png";
  const file = new File([fileContent], fileName, { type: fileType });
  const formData = new FormData();
  formData.append("contas", reqNumber);
  formData.append("image", file);
  const { data } = await request(
    METHOD_TYPES.POST,
    `${ENDPOINTS.UPLOAD_ASSETS}`,
    formData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  return {
    mediaResponse: data,
  };
};

export const downloadMedia = async ({ mediaUrl }) => {
  const response = await request(
    METHOD_TYPES.POST,
    `${ENDPOINTS.DOWNLOAD_FILE}`,
    {
      link: mediaUrl,
    }
  );
  return {
    fileData: response?.data?.data,
    status: response?.status,
  };
};
