import { request } from "../../core/networkRequests";
import { METHOD_TYPES } from "../../../shared/constants/method-types";
import { ENDPOINTS } from "../../../shared/constants/endpoints";

export const getClientLogo = async ({ imageUrl }) => {
  const { data } = await request(
    METHOD_TYPES.POST,
    `${ENDPOINTS.DOWNLOAD_FILE}`,
    {
      link: imageUrl,
    }
  );
  return {
    logoData: data.data,
  };
};
