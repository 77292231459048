import { Outlet } from "react-router-dom";
import Footer from "../../commonComponents/footer";
import Header from "../../commonComponents/header";
import "./appLayout.scss";

export default function AppLayout() {
  return (
    <div className="main-app-div">
      <div className="header-app-div">
        <Header />
      </div>
      <div className="content-app-div">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}
