import React, { useEffect, useState } from "react";

const Base64ToImage = (props) => {
  const [imageUrl, setImageUrl] = useState();
  const base64String = props.baseString; // Replace with your actual Base64 string

  // Convert Base64 string to Blob
  const base64ToBlob = (base64String) => {
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }

    const blob = new Blob([new Uint8Array(byteArrays)], { type: "image/jpeg" }); // Modify the type as per your image format
    return blob;
  };

  // Convert Blob to image URL
  const blobToImageUrl = (blob) => {
    const urlCreator = window.URL || window.webkitURL;
    return urlCreator.createObjectURL(blob);
  };

  useEffect(() => {
    setImageUrl(blobToImageUrl(base64ToBlob(base64String)));
  }, []);

  const handleClick = () => {
    if (props?.onClick !== undefined) {
      props?.onClick(imageUrl);
    }
  };

  return (
    <div>
      <img
        src={imageUrl}
        alt="Media"
        className={props.className}
        onClick={handleClick}
      />
    </div>
  );
};

export default Base64ToImage;
