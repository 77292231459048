import { useContext } from "react";
import { localText } from "../../../../constants/text";
import ArrowList from "../../../commonComponents/arrowList";
import { AppContext } from "../../../../contexts/app.context";
import { InspectionContext } from "../../../../contexts/inspection.context";
import Media from "../media";
import { CONSTANTS } from "../../../../shared/constants/constants";

export default function Requested(props) {
  const { inspection } = useContext(AppContext);
  const {
    inspectionToBeSent,
    subStepMode,
    setInstructionsContent,
    setSelectedNameForMedia,
    setAnimationDirection,
  } = useContext(InspectionContext);

  const handleClick = (data) => {
    setAnimationDirection(1);
    setSelectedNameForMedia(data.name);
    setInstructionsContent(data);
  };

  return (
    <>
      {subStepMode === null ? (
        <>
          <ArrowList
            title={localText.pleaseUplload}
            data={inspection.supporting_docs}
            onClick={handleClick}
            inspectionToBeSent={inspectionToBeSent[CONSTANTS.REQUESTED_MEDIA]}
          />
        </>
      ) : (
        <Media />
      )}
    </>
  );
}
