import React, { useContext, useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import { Typography } from "@mui/material";
import ButtonComponent from "../../../commonComponents/button";
import { ModalContext } from "../../../../contexts/modal.context";
import { AppContext } from "../../../../contexts/app.context";
import {
  CONSTANTS,
  FRAUD_NOTICE_LABELS,
} from "../../../../shared/constants/constants";

const FraudNotice = ({ title, description }) => {
  const { closeModal } = useContext(ModalContext);
  const { acceptFraudNotice, setAcceptFraudNotice } = useContext(AppContext);
  const [checked, setChecked] = useState(false);
  const [opacity, setOpacity] = useState(CONSTANTS.DISABLED_OPACITY);

  const submitTerms = () => {
    setAcceptFraudNotice(true);
    closeModal();
  };

  useEffect(() => {
    const opacity =
      acceptFraudNotice || checked ? "1" : CONSTANTS.DISABLED_OPACITY;
    setOpacity(opacity);
  }, [acceptFraudNotice, checked]);

  let buttonParams = {};
  if (acceptFraudNotice) {
    buttonParams = {
      title: CONSTANTS.CLOSE,
      onClick: () => closeModal(),
    };
  } else {
    buttonParams = {
      title: CONSTANTS.ACCEPT,
      onClick: () => submitTerms(),
      disabled: acceptFraudNotice ? true : !checked,
    };
  }

  return (
    <>
      <Typography
        id="modal-modal-title"
        variant="h6"
        color={"black"}
        fontWeight={600}
      >
        {title}
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2, color: "#3D515A" }}>
        {description}
      </Typography>
      <div
        style={{
          display: "flex",
          margin: "10px 0px 10px 0px",
          alignItems: "center",
        }}
      >
        <Checkbox
          style={{
            margin: 0,
            padding: 0,
            marginRight: 5,
            opacity: acceptFraudNotice ? CONSTANTS.DISABLED_OPACITY : opacity,
          }}
          checked={acceptFraudNotice ? true : checked}
          disabled={acceptFraudNotice}
          onChange={(event) => setChecked(event.target.checked)}
          sx={{
            color: "#3C4D6A",
            "&.Mui-checked": {
              color: "#0066CC",
            },
          }}
        />
        <Typography
          style={{ cursor: "pointer" }}
          color={"#3D515A"}
          onClick={() => setChecked(!checked)}
        >
          {!acceptFraudNotice
            ? FRAUD_NOTICE_LABELS.ACKNOWLEDGE
            : `${FRAUD_NOTICE_LABELS.ACKNOWLEDGED} ${new Date().toLocaleString(
                "en-us",
                {
                  day: "numeric",
                  year: "numeric",
                  month: "short",
                }
              )}`}
        </Typography>
      </div>
      <ButtonComponent
        className={"button-contained"}
        style={{
          opacity,
        }}
        {...buttonParams}
      />
    </>
  );
};

FraudNotice.defaultProps = {
  title: FRAUD_NOTICE_LABELS.TITLE,
  description: FRAUD_NOTICE_LABELS.DESCRIPTION,
};

export default FraudNotice;
