import { request } from "../../core/networkRequests";
import { METHOD_TYPES } from "../../../shared/constants/method-types";
import { ENDPOINTS } from "../../../shared/constants/endpoints";

export const getInspection = async ({ inspectionId }) => {
  const response = await request(
    METHOD_TYPES.GET,
    `${ENDPOINTS.GET_SINGLE_INSPECTION}/${inspectionId}`,
    {}
  );
  return {
    inspectionData: response?.data?.data?.inspection,
    status: response?.data?.status,
  };
};

export const getFaq = async () => {
  const response = await request(METHOD_TYPES.GET, `${ENDPOINTS.GET_FAQ}`, {});
  return {
    faqData: response?.data?.data?.faqList,
    status: response?.data?.status,
  };
};

export const sendInspection = async ({ inspectionData }) => {
  const response = await request(
    METHOD_TYPES.PUT,
    `${ENDPOINTS.SEND_INSPECTION}`,
    inspectionData
  );
  return { status: response?.data?.status };
};
