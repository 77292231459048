import { isValidElement, useState } from "react";

const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [header, setHeader] = useState("");
  const [content, setContent] = useState("");
  const [footer, setFooter] = useState("");
  const [disableBackDropClose, setDisableBackDropClose] = useState(false);
  const [version, setVersion] = useState(0);
  const [hideCloseIcon, setHideCloseIcon] = useState(false);

  const openModal = ({
    header,
    content,
    footer,
    version,
    disableBackDropClose,
    hideCloseIcon,
  }) => {
    setIsOpen(true);
    if (isValidElement(header)) {
      setHeader(header);
    }
    if (isValidElement(content)) {
      setContent(content);
    }
    if (isValidElement(footer)) {
      setFooter(footer);
    }
    setVersion(version);
    setDisableBackDropClose(disableBackDropClose);
    setHideCloseIcon(hideCloseIcon);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return {
    isOpen,
    header,
    content,
    footer,
    openModal,
    closeModal,
    version,
    disableBackDropClose,
    hideCloseIcon,
  };
};

export { useModal };
