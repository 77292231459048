import React, { useContext } from "react";
import { LoaderContext } from "../../../contexts/loader.context";
import MakePortal from "../../../contexts/make.portal";
import "./loader.css";
import { Modal } from "@mui/material";

const Loader = (props) => {
  const { isLoading } = useContext(LoaderContext);

  if (!isLoading) return null;

  return (
    <MakePortal selector="#root-loader">
      <Modal open={true} aria-label="loading" className="center-div">
        <div id="loader-wrapper" style={{ zIndex: "1399" }}>
          <div id="loader"></div>
        </div>
      </Modal>
    </MakePortal>
  );
};
export default Loader;
