import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import Sms from "../screens/smsWorkFlow";
import SplashScreen from "../screens/splashscreen";
import Inspection from "../screens/inspection";
import AppLayout from "./layouts/AppLayout";
import RootLayout from "./layouts/RootLayout";
import ServiceUnavailable from "../errors/ServiceUnavailable";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<RootLayout />}>
      <Route
        path="/:userId/:inspectionId/:token/inspection"
        element={<SplashScreen redirectTo="/inspection" />}
      />
      <Route
        path="/:userId/:inspectionId/:token/mobile"
        element={<SplashScreen redirectTo="/sms" />}
      />
      <Route path="sms" element={<Sms />} />
      <Route path="service-unavailable" element={<ServiceUnavailable />} />
      <Route element={<AppLayout />}>
        <Route path="inspection" element={<Inspection />} />
      </Route>
    </Route>
  )
);

export default function Router() {
  return <RouterProvider router={router} />;
}
