const blueColor = "#13223C";
const lightGrey = "#68758B";
const placeHolder = "#596981";
const buttonColor = "#0066CC";
const white = "#FFFFFF";

export const styles = {
  textColor: {
    color: blueColor,
  },
  lightGrey: {
    color: lightGrey,
  },
  textPlaceHolder: {
    color: placeHolder,
  },
  buttonStyle: {
    backgroundColor: buttonColor,
    color: white,
  },
  menuItem: {
    fontSize: 14,
    color: lightGrey,
  },
};
