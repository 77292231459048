import ArrowForward from "@mui/icons-material/ArrowForward";
import React, { useContext } from "react";
import "./arrowList.scss";

import DoneIcon from "@mui/icons-material/Done";
import { InspectionContext } from "../../../contexts/inspection.context";
import { CONSTANTS } from "../../../shared/constants/constants";
import { isArrayEmpty } from "../../../shared/helper";

export default function ArrowList(props) {
  const { inspectionToBeSent } = useContext(InspectionContext);

  const returnDoneIcon = (data) => {
    let showDoneIcon = false;
    switch (data.type) {
      case CONSTANTS.COMMENT:
        if (data.commentData) showDoneIcon = true;
        break;
      case CONSTANTS.IMAGE_VIDEO:
        if (data.assets.length) showDoneIcon = true;
        break;
      default:
        const requestedMedia = inspectionToBeSent[CONSTANTS.REQUESTED_MEDIA];
        if (!isArrayEmpty(requestedMedia)) {
          const [updatesAssests] = requestedMedia.filter(
            (item) => data.name === item.name
          );
          if (
            updatesAssests?.assets.length >=
            data.min_image + data.min_video
          ) {
            showDoneIcon = true;
          }
        }
        break;
    }
    if (!showDoneIcon) {
      return <div className="show-completed"></div>;
    }
    return (
      <DoneIcon className="show-completed icon" style={{ color: "#047D26" }} />
    );
  };

  return (
    <>
      <div className="arrow-list-header">{props.title}:</div>
      <div style={{ marginBottom: 10 }}>
        {props.data.map((item, key) => (
          <button
            key={key}
            onClick={() => {
              props.onClick(item);
            }}
            className="list"
          >
            <div className="list-content">
              {returnDoneIcon(item)}
              <div className="sub-header-arrow-list">{item.name}</div>
            </div>
            <ArrowForward color="primary" />
          </button>
        ))}
      </div>
    </>
  );
}
