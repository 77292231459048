export const getStoredAuthToken = () => {
  const token = localStorage.getItem("authToken");
  if (token) {
    return token;
  }
};

export const storeAuthToken = (token) =>
  localStorage.setItem("authToken", token);

export const removeStoredAuthToken = () => localStorage.removeItem("authToken");

export const getInspectionDetails = () => {
  const data = localStorage.getItem("inspectionDetails");
  if (data) {
    return JSON.parse(data);
  }
};

export const storeInspectionDetails = (data) => {
  localStorage.setItem("inspectionDetails", JSON.stringify(data));
};

export const removeStoredInspectionDetails = () =>
  localStorage.removeItem("inspectionDetails");
