import { useContext, useEffect, useState } from "react";
import { inspectionService } from "../services/functional";
import { CONSTANTS, ERROR_MESSAGE, STEPS } from "../shared/constants/constants";
import { postMedia } from "../services/functional/media";
import { AppContext } from "../contexts/app.context";
import { LoaderContext } from "../contexts/loader.context";
import { b64toBlob, deleteFilesByName } from "../shared/helper";
import { ModalContext } from "../contexts/modal.context";
import { Alert, AlertTitle } from "@mui/material";

const useInspection = () => {
  const steps = [STEPS.START, STEPS.REQUESTED, STEPS.ADDITIONAL, STEPS.REVIEW];
  const [cameraMode, setCameraMode] = useState(null);
  const [subStepMode, _setSubStepMode] = useState(null);
  const [instructionsContent, _setInstructionsContent] = useState({});
  const [activeStep, _setActiveStep] = useState(1);
  const [activeStepName, setActiveStepName] = useState(STEPS.START);
  const { inspectionIdLocal, setHideHeaderFooter, inspection } =
    useContext(AppContext);
  const { setLoader } = useContext(LoaderContext);
  const { openModal } = useContext(ModalContext);
  const [selectedNameForMedia, setSelectedNameForMedia] = useState("");
  const [inspectionToBeSent, setInspectionToBeSent] = useState({
    comment: inspection?.additional_docs?.comments || "",
    [CONSTANTS.REQUESTED_MEDIA]: inspection?.supporting_docs || [],
    [CONSTANTS.ADDITIONAL_MEDIA]: inspection?.additional_docs?.assets || [],
  });
  const [showConfirmationScreen, setShowConfirmationScreen] = useState(false);
  const [animationDirection, _setAnimationDirection] = useState(0);

  const setActiveStep = (key) => {
    _setActiveStep(key);
    setActiveStepName(steps[key - 1]);
  };

  const setAnimationDirection = (direction) => {
    _setAnimationDirection(direction);
  };

  useEffect(() => {
    console.log("inspectionToBeSent", inspectionToBeSent);
  }, [inspectionToBeSent]);

  const imageVideoUpload = async ({
    file,
    inspectionRequestNumber,
    mediaType,
  }) => {
    try {
      setLoader(true);
      let blobUrl = "";
      let fileContent = "";
      if (mediaType === CONSTANTS.VIDEO) {
        fileContent = file[0];
        blobUrl = URL.createObjectURL(new Blob(file));
      } else {
        fileContent = b64toBlob(file.split(",")[1], "image/png");
        blobUrl = URL.createObjectURL(fileContent);
      }

      let post = await postMedia(
        fileContent,
        inspectionRequestNumber,
        mediaType
      );
      if (post?.mediaResponse?.status?.code === 200) {
        setCameraMode(null);
        setHideHeaderFooter(false);
        let imageData = {
          filename: post.mediaResponse.data.path.split(/[/]+/).pop(),
          type: mediaType,
          local: blobUrl,
          path: post.mediaResponse.data.path,
        };

        if (activeStepName === STEPS.REQUESTED) {
          if (inspectionToBeSent[CONSTANTS.REQUESTED_MEDIA].length > 0) {
            setInspectionToBeSent((prevState) => {
              const newName = { ...prevState };
              // console.log("", "coming ");
              const filteredNames = newName[CONSTANTS.REQUESTED_MEDIA].filter(
                (item) => item.name === selectedNameForMedia
              );
              if (filteredNames.length > 0) {
                if (Object.keys(imageData).length > 0) {
                  filteredNames[0].assets.push(imageData);
                }
                imageData = {};
                // filteredNames[0].assets;
              } else {
                if (Object.keys(imageData).length > 0) {
                  instructionsContent.assets = [imageData];
                  newName[CONSTANTS.REQUESTED_MEDIA].push({
                    ...instructionsContent,
                    status: true,
                  });
                }
                imageData = {};
              }
              return newName;
            }, []);
          } else {
            instructionsContent.assets = [imageData];
            feedData(
              CONSTANTS.REQUESTED_MEDIA,
              { ...instructionsContent, status: true },
              post.mediaResponse.data.path
            );
          }
        } else {
          feedData(
            CONSTANTS.ADDITIONAL_MEDIA,
            { ...imageData },
            post.mediaResponse.data.path
          );
        }
      } else {
        openModal({
          content: (
            <Alert severity="error">
              <AlertTitle>{ERROR_MESSAGE.TEXT}</AlertTitle>
              {post?.mediaResponse?.path}
            </Alert>
          ),
        });
      }
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoader(false);
    }
  };
  const feedData = (toUpdate, value, path) => {
    if (toUpdate === CONSTANTS.COMMENT) {
      setInspectionToBeSent((prevState) => ({
        ...prevState,
        [toUpdate]: value,
      }));
    } else {
      setInspectionToBeSent((prevState) => ({
        ...prevState,
        [toUpdate]: [...prevState[toUpdate], value],
      }));
    }
  };

  const deleteMediaFromState = ({ fileName, from }) => {
    setInspectionToBeSent((prevState) => ({
      ...prevState,
      [from]: deleteFilesByName(inspectionToBeSent[from], fileName),
    }));
  };

  const sendInspection = async () => {
    try {
      setLoader(true);
      const { status } = await inspectionService.sendInspection({
        inspectionData: postInspectionData(),
      });

      if (status?.code === 200) {
        setShowConfirmationScreen(true);
      }
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoader(false);
    }
  };

  const setSubStepMode = (params) => {
    if (params) {
      const { type, data } = params;
      if (data !== null && type === CONSTANTS.MEDIA) {
        const { min_image, min_video } = data;
        if (min_image) {
          _setSubStepMode(CONSTANTS.IMAGE);
        }
        if (min_video) {
          _setSubStepMode(CONSTANTS.VIDEO);
        }
        if (min_image && min_video) {
          _setSubStepMode(CONSTANTS.IMAGE_VIDEO);
        }
      } else if (type === CONSTANTS.COMMENT || type === CONSTANTS.IMAGE_VIDEO) {
        _setSubStepMode(type);
      }
    } else {
      setAnimationDirection(-1);
      _setSubStepMode(null);
    }
  };

  const setInstructionsContent = (data) => {
    _setInstructionsContent(data);
    if (activeStepName === STEPS.REQUESTED) {
      const { min_image, min_video } = data;
      setSubStepMode({
        type: CONSTANTS.MEDIA,
        data: {
          min_image,
          min_video,
        },
      });
    }

    if (activeStepName === STEPS.ADDITIONAL) {
      const { type } = data;
      setSubStepMode({ type });
    }
  };

  const postInspectionData = () => {
    let payload = {
      inspection_id: inspectionIdLocal,
      status: "SUBMITTED",
      step: "4",
      image_names: getImageNames(),
      inspection_data: {
        supporting_docs: inspectionToBeSent[CONSTANTS.REQUESTED_MEDIA],
        additional_docs: {
          comments: inspectionToBeSent.comment,
          status: false,
          assets: inspectionToBeSent[CONSTANTS.ADDITIONAL_MEDIA],
        },
      },
    };
    return payload;
  };

  const getImageNames = () => {
    const requestedMedia = inspectionToBeSent[CONSTANTS.REQUESTED_MEDIA].map(
      (record) => {
        const k = record?.assets?.map((item) => {
          return { fileName: item?.filename };
        });
        return k;
      }
    );
    const additionalMedia = inspectionToBeSent[CONSTANTS.ADDITIONAL_MEDIA].map(
      (record) => {
        return { fileName: record?.filename };
      }
    );
    Array.prototype.push.apply(requestedMedia, additionalMedia);
    return requestedMedia.flat();
  };

  return {
    steps,
    activeStep,
    setActiveStep,
    activeStepName,
    inspectionToBeSent,
    feedData,
    selectedNameForMedia,
    setSelectedNameForMedia,
    deleteMediaFromState,
    sendInspection,
    imageVideoUpload,
    cameraMode,
    setCameraMode,
    subStepMode,
    setSubStepMode,
    instructionsContent,
    setInstructionsContent,
    postInspectionData,
    showConfirmationScreen,
    animationDirection,
    setAnimationDirection,
  };
};

export { useInspection };
