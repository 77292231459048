import { useState } from "react";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Minimize } from "@mui/icons-material";
import { isObjEmpty } from "../../../shared/helper";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  borderLeft: "none"
}));

export default function CustomizedAccordions({ data }) {
  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <div
      style={{ height: 200, overflowY: "scroll" }}
      className="accordion-wrapper"
    >
      {!isObjEmpty(data) &&
        data.map((item, key) => (
          <Accordion
            key={key}
            expanded={expanded === `panel${key}`}
            onChange={handleChange(`panel${key}`)}
            style={{borderLeft:"none", borderBottom:"none", borderRight:"none"}}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              expandIcon={
                expanded === `panel${key}` ? (
                  <Minimize style={{ marginTop: -12 }} />
                ) : (
                  <AddIcon />
                )
              }
              style={{backgroundColor:"white"}}
            >
              <div className="faq-header-style">{item.faq_title}</div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="faq-content-style">{item.faq_desc}</div>
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  );
}
