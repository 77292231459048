import React, { useContext, useEffect, useState } from "react";
import TutorialVideo from "../../commonComponents/tutorialVideo";
import { Close } from "@mui/icons-material";
import { Typography } from "@mui/material";
import CustomizedAccordions from "../../commonComponents/accordion";
import { inspectionService } from "../../../services/functional";
import { isObjEmpty } from "../../../shared/helper";
import videoImage from "../../../assets/images/video.png";
import { localText } from "../../../constants/text";
import { CONSTANTS } from "../../../shared/constants/constants";
import { LoaderContext } from "../../../contexts/loader.context";

export default function Faq() {
  const { setLoader } = useContext(LoaderContext);
  const [selectedButton, setSelectedButton] = useState(0);
  const [faqList, setFaqList] = useState({});
  const [allFaqList, setAllFaqList] = useState({});
  const [tabList, setTabList] = useState([CONSTANTS.ALL]);
  const [activeTab, setActiveTab] = useState(CONSTANTS.ALL);
  const [openVideoTutorial, setOpenVideoTutorial] = useState(false);
  const [transformedFaqs, setTransformedFaqs] = useState({});
  useEffect(() => {
    (async () => {
      try {
        setLoader(true);
        const { faqData, status } = await inspectionService.getFaq();
        if (status?.code === 200 && !isObjEmpty(faqData)) {
          const allTabs = [...new Set(faqData.map((item) => item.faq_cat))];
          setTabList([...tabList, ...allTabs]);
          setTransformedFaqs(getTransformedFaqs(faqData));
          setAllFaqList(faqData);
        }
      } catch (error) {
        setLoader(false);
      } finally {
        setLoader(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (activeTab === CONSTANTS.ALL) {
      setFaqList(allFaqList);
    } else {
      setFaqList(transformedFaqs[activeTab]);
    }
  }, [activeTab, faqList, transformedFaqs]);

  const getTransformedFaqs = (faqData) => {
    const faqs = {};
    faqData.forEach((item, index) => {
      if (index > 0) {
        faqs[item.faq_cat] = [...new Set(faqs[item.faq_cat]), item];
      } else {
        faqs[item.faq_cat] = [item];
      }
    });
    return faqs;
  };

  const closeVideoTutorial = () => {
    setOpenVideoTutorial(false);
  };

  return (
    <>
      <div style={{ float: "right" }}>
        <Close style={{ color: "white" }} />
      </div>
      <Typography
        variant="h5"
        textAlign={"center"}
        marginBottom={2}
        fontWeight={"bold"}
      >
        {localText.tutorialsAndFaq}
      </Typography>
      <div className="video-image-wrapper">
        <img
          alt="Video"
          src={videoImage}
          style={{ width: "100%" }}
          onClick={() => setOpenVideoTutorial(true)}
        />
        {/* <iframe src="https://player.vimeo.com/video/812791879?h=da9a7d708d" style={{width:"100%"}}/> */}
      </div>
      <div className="faq-list-wrapper">
        {!isObjEmpty(tabList) &&
          tabList.map((item, key) => (
            <button
              key={key}
              onClick={() => {
                setSelectedButton(key);
                setActiveTab(item);
              }}
              className={
                selectedButton === key
                  ? "faq-btn-list-selected"
                  : "faq-btn-list"
              }
            >
              {item}
            </button>
          ))}
      </div>
      <Typography variant="h6" marginTop={1} marginBottom={2}>
        {localText.faq}
      </Typography>
      <CustomizedAccordions data={faqList} />
      <TutorialVideo
        isOpen={openVideoTutorial}
        handleClose={closeVideoTutorial}
      />
    </>
  );
}
