import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";

const AppStepper = (props) => {
  const activeStep = props.activeStep;

  const steps = [1, 2, 3, 4];

  const getStepContent = (step) => {
    switch (step) {
      case 1:
        return "Start";
      case 2:
        return "Requested";
      case 3:
        return "Additional";
      case 4:
        return "Review";
      default:
        return "Unknown Step";
    }
  };

  const getMarginLeft = () => {
    if (activeStep === 1) {
      return 5;
    } else if (activeStep === 2) {
      return -13;
    } else if (activeStep === 3) {
      return -10;
    }
    return 0;
  };

  const getCompleted = (index) => {
    return (
      <div
        key={index}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: 27,
        }}
      >
        <CheckCircleSharpIcon style={{ color: "#047D26" }} />
        <div
          style={{ height: "3px", width: "70px", backgroundColor: "#BDC4C7" }}
        />
      </div>
    );
  };

  const getCurrent = (showProgress, index) => {
    return (
      <div key={index}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              border: "2px solid #2490EE",
              height: 36,
              width: 36,
              borderRadius: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                border: "2px solid #BDC4C7",
                height: 20,
                width: 20,
                borderRadius: 20,
              }}
            ></div>
          </div>
          <div
            hidden={!showProgress}
            style={{ height: "3px", width: "70px", backgroundColor: "#BDC4C7" }}
          />
        </div>
        <div
          style={{
            fontSize: 14,
            fontWeight: 400,
            color: "#68758B",
            marginLeft: getMarginLeft(),
            marginTop: "10px",
          }}
        >
          {getStepContent(activeStep)}
        </div>
      </div>
    );
  };

  const getNotCompleted = (showProgress, index) => {
    return (
      <div
        key={index}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: 27,
        }}
      >
        <div
          style={{
            border: "2px solid #BDC4C7",
            height: 20,
            width: 20,
            borderRadius: 20,
          }}
        ></div>
        <div
          hidden={!showProgress}
          style={{ height: "3px", width: "70px", backgroundColor: "#BDC4C7" }}
        ></div>
      </div>
    );
  };

  return (
    <div style={{ zIndex: -9, position: "relative" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "center",
          marginTop: 30,
          transform: "scale(0.9)",
        }}
      >
        {steps.map((step, index) => {
          const showProgress = step !== steps.length;
          if (step < activeStep) {
            return getCompleted(index);
          }
          if (step == activeStep) {
            return getCurrent(showProgress, index);
          }
          return getNotCompleted(showProgress, index);
        })}
      </div>
    </div>
  );
};

export default AppStepper;
