import React from "react";
import "./doubleButtonPopUp.scss"

export default function DoubleButtonPopUp(props) {
  return (
    <>
      <div className="double-button-pop-up-div">
        <button onClick={props.onClickOne} className="btn-1">
          {props.titleOne}
        </button>
        <button onClick={props.onClickTwo} className="btn-2">
          {props.titleTwo}
        </button>
      </div>
    </>
  );
}