import React, { useContext, useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { mediaService } from "../../../services/functional";
import { checkIfValidBase64 } from "../../../shared/helper";
import Base64ToImage from "../basetoimage";
import { ModalContext } from "../../../contexts/modal.context";

export default function RenderFile({ fileUrl, className, alt }) {
  const [src, setSrc] = useState();
  const { openModal } = useContext(ModalContext);

  useEffect(() => {
    (async () => {
      const { fileData, status } = await mediaService.downloadMedia({
        mediaUrl: fileUrl,
      });
      if (status === 200 && checkIfValidBase64(fileData?.asset)) {
        setSrc(fileData?.asset);
      }
    })();
  }, [fileUrl]);

  const handleClick = (imageUrl) => {
    openModal({
      content: (
        <>
          <div>
            <img
              src={imageUrl}
              alt="maximized"
              style={{ height: "80vh", width: "100%" }}
            />
          </div>
        </>
      ),
      version: 1,
    });
  };

  return (
    <>
      {src ? (
        <Base64ToImage
          baseString={src}
          className={className}
          alt={alt}
          onClick={handleClick}
        />
      ) : (
        <Box
          className={className}
          sx={{ display: "flex" }}
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size="1.4rem" />
        </Box>
      )}
    </>
  );
}
