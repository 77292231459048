export const localText = {
  claim: "Claim",
  mobile: "Mobile Number",
  send: "Send",
  unableToSend: "Unable to send!",
  tryAgain: "check your mobile number and try again",
  mobileDataRate: "Mobile and Data rates apply.. ",
  tbd: "TBD",
  success: "Success",
  checkMobile: "Check your mobile device for the inspection link",
  start: "Start",
  virtualInspection: "Virtual Inspection",
  howitWorks: "How it Works:",
  tutorial: "Tutorial",
  next: "Next",
  vin: "VIN",
  back: "Back",
  review: "Review",
  pleaseUplload: "Please upload the requested support",
  photos: "Photos",
  photo: "Photo",
  video: "Video",
  noLongerActiveDesc:
    "A claim decision has been made or the virtual inspection request has been cancelled.",
  noLonger: "This link is no longer active",
  closepage: "Close Page",
  save: "Save",
  addPhotos: "Add photos of the",
  instruction: "Instruction",
  notStarted: {
    useYourDevice:
      " Use your device's camera to provide the requested photos/videos. Your progress will be saved automatically.",
    toReduce:
      " To reduce delays in processing the claim, please provide what is being requested.",
    youWillAble:
      " You will be able to provide additional information before submitting.",
  },
  addOther: "Add other details? (optional)",
  imagesOrVideos: "Images/Video",
  comments: "Comments",
  additionalComments: "Additional Comments",
  moreDetails: "More details are better...",
  comment: "comment",
  readyForSubmit: {
    submitForReview: "Submit for review",
    ifYouReady:
      "If you are ready to submit this information to us for review, please click ",
    submit: "Submit.",
  },
  oneMoment: "One moment while we",
  yourInspection: "submit your inspection",
  submit: "Submit",
  thankYou:
    " Thank you. We have received the inspection details and will begin our review. If we have further questions, we will let you know.",
  closePage: "Close Page",
  tutorialsAndFaq: "Tutorial & FAQ",
  faq: "FAQ",
  retake: "Retake",
  usePhoto: "Use Photo",
  useVideo: "Use Video",
  cancel: "Cancel",
  switchCamera: "Switch camera",
  removeAttachment: "Remove Attachment",
  areYouSure: "Are you sure you want to remove the attachment?",
  ok: "ok",
};
