import { createContext } from "react";
import Loader from "../components/commonComponents/loader/loader";
import { useLoader } from "../hooks/useLoader";

const LoaderContext = createContext({
  setLoader: () => {},
  isLoading: false,
});

LoaderContext.displayName = "Loader";

const LoaderProvider = ({ children }) => {
  const { isLoading, setLoader } = useLoader();
  const context = { isLoading, setLoader };

  return (
    <LoaderContext.Provider value={context}>
      <Loader />
      {children}
    </LoaderContext.Provider>
  );
};

export { LoaderContext, LoaderProvider };
