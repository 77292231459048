import { useContext } from "react";
import Box from "@mui/material/Box";
import ArrowList from "../../../commonComponents/arrowList";
import Comment from "./comment";
import { localText } from "../../../../constants/text";
import CameraVideo from "../media/cameraVideo";
import { InspectionContext } from "../../../../contexts/inspection.context";
import { CONSTANTS } from "../../../../shared/constants/constants";
import { AppContext } from "../../../../contexts/app.context";

export default function Additional(props) {
  const { inspection } = useContext(AppContext);
  const {
    inspectionToBeSent,
    setSubStepMode,
    subStepMode,
    setSelectedNameForMedia,
    setInstructionsContent,
    setAnimationDirection,
  } = useContext(InspectionContext);

  const handleClick = (data) => {
    setAnimationDirection(1);
    setSelectedNameForMedia(data.name);
    setInstructionsContent(data);
  };
  return (
    <>
      {subStepMode === CONSTANTS.COMMENT ? (
        <Comment
          comment={inspectionToBeSent.comment || inspection.comments}
          goBack={() => setSubStepMode(null)}
        />
      ) : subStepMode === CONSTANTS.IMAGE_VIDEO ? (
        <CameraVideo from={CONSTANTS.ADDITIONAL_MEDIA} />
      ) : (
        <>
          <Box marginTop={3} marginBottom={3}>
            <ArrowList
              title={localText.addOther}
              data={[
                {
                  name: localText.imagesOrVideos,
                  type: CONSTANTS.IMAGE_VIDEO,
                  assets: inspectionToBeSent[CONSTANTS.ADDITIONAL_MEDIA],
                  instructions: CONSTANTS.ADD_PHOTOS_AND_VIDEOS,
                },
                {
                  name: localText.comments,
                  type: CONSTANTS.COMMENT,
                  commentData: inspectionToBeSent.comment,
                },
              ]}
              onClick={handleClick}
              inspectionToBeSent={
                inspectionToBeSent[CONSTANTS.ADDITIONAL_MEDIA]
              }
            />
          </Box>
        </>
      )}
    </>
  );
}
