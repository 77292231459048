import { createContext } from "react";
import { useImageModal } from "../hooks/useImageModal";
import ImageModalComponent from "../components/commonComponents/imageModal";

const ImageModalContext = createContext({
  pageType: "",
});

ImageModalContext.displayName = "Modal";

const ModalImageProvider = ({ children }) => {
  const {
    isOpen,
    content,
    openImageModal,
    closeImageModal,
    disableBackDropClose,
  } = useImageModal();
  const context = {
    isOpen,
    content,
    openImageModal,
    closeImageModal,
    disableBackDropClose,
  };
  return (
    <ImageModalContext.Provider value={context}>
      <ImageModalComponent />
      {children}
    </ImageModalContext.Provider>
  );
};

export { ImageModalContext, ModalImageProvider };
