import React from "react";
import { localText } from "../../../constants/text";
import DoubleButtonPopUp from "../doubleButtonPopUp";
import "../../styleSheet/modals.scss"

export default function MessageModal(props) {
  return (
    <div className="delete-modal">
      <div className="font-bold">{localText.removeAttachment}</div>
      <div className="mt-20">{localText.areYouSure}</div>
      <div>
        <DoubleButtonPopUp
          titleOne={localText.cancel}
          titleTwo={localText.ok}
          onClickOne={props.clickCancel}
          onClickTwo={props.clickOk}
        />
      </div>
    </div>
  );
}
