export const CONSTANTS = {
  ACCEPT: "Accept",
  CLOSE: "Close",
  INSPECTION: "inspection",
  COMMENT: "comment",
  VIDEO: "video",
  VIDEOS: "Video(s)",
  PHOTOS: "Photo(s)",
  CAMERA: "camera",
  DISABLED_OPACITY: 0.5,
  ALL: "All",
  NEXT: "Next",
  BACK: "Back",
  REVIEW: "Review",
  IMAGE_VIDEO: "image/video",
  MEDIA: "media",
  IMAGE: "image",
  INSTRUCTIONS: "Instructions",
  ADDITIONAL_MEDIA: "additionalMedia",
  REQUESTED_MEDIA: "requestedMedia",
  ADD_PHOTOS_AND_VIDEOS: "Add photo(s) and video(s)",
  PRIVACY_POLICY: "Privacy Policy",
  TERMS_AND_CONDITIONS: "Terms and Conditions",
  TUTORIALS_AND_FAQ: "Tutorials & Faq",
  ALL_RIGHTS_RESERVED: "Assurant, Inc. All rights reserved",
};

export const INSPECTION_STATUSES = {
  SUBMITTED: "SUBMITTED",
};

export const STEPS = {
  START: "start",
  REQUESTED: "requested",
  ADDITIONAL: "additional",
  REVIEW: "review",
};

export const FRAUD_NOTICE_LABELS = {
  TITLE: "Fraud Notice",
  DESCRIPTION:
    "Any person who  knowingly presents materially false information or conceals any material information for the purpose or misleading or defrauding the reciept or any other person may be subject to legal action including civil and criminal penalties",
  ACKNOWLEDGE: "I acknowledge and accept",
  ACKNOWLEDGED: "I acknowledged and accepted on",
};

export const ERROR_MESSAGE = {
  401: "The link you followed has expired",
  400: "Bad request",
  TEXT: "Error",
  NO_MIME_TYPE_FOUND: "No suitable mimetype found for this device.",
  OOPS: "Oops!",
  SERVICE_UNAVAILABLE: "Something went wrong, please try again later.",
  RETURN_TO_LINK: "Please click 'Go Back' to return top the screen",
  CONTINUE_TO_REFRESH:
    "Continue to refresh? Any progress you made may not be saved.",
  LINK_INACTIVE: "This link is no longer active",
  LINK_INACTIVE_MESSAGE:
    "A claim decision has been made or the virtual inspection request has been cancelled.",
  BUTTONS: {
    OK: {
      KEY: "ok",
      TEXT: "Ok",
    },
    CLOSE: {
      KEY: "close",
      TEXT: "Close Page",
    },
    BACK: {
      KEY: "back",
      TEXT: "Go Back",
    },
  },
};

export const SITE_URL = {
  ASSURANT_HOME_PAGE: "https://www.assurant.com",
  PRIVACY_POLICY: "https://www.assurant.com/privacypolicy",
  TERMS_AND_CONDITIONS:
    "https://reinsurance.assurantauto.com/termsandconditions",
  SERVICE_UNAVAILABLE: "/service-unavailable",
};
