import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  inspectionService,
  storeService,
  clientService,
} from "../services/functional";
import { authHelper } from "../services/app";
import { isObjEmpty } from "../shared/helper";
import { ERROR_MESSAGE, SITE_URL } from "../shared/constants/constants";

const useApp = () => {
  const { inspectionId, token, userId } = useParams();
  const navigate = useNavigate();
  const [inspection, setInspection] = useState({});
  const [store, setStore] = useState({});
  const [clientLogo, setClientLogo] = useState({});
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [acceptFraudNotice, setAcceptFraudNotice] = useState(false);
  const [hideHeaderFooter, setHideHeaderFooter] = useState(false);
  const [inspectionIdLocal, setInspectionIdLocal] = useState("");

  useEffect(() => {
    if (token) {
      authHelper.removeStoredAuthToken();
      authHelper.storeAuthToken(token);
    }
  }, [token]);

  useEffect(() => {
    if (userId && inspectionId) {
      authHelper.storeInspectionDetails({
        assigned_id: userId,
        _id: inspectionId,
      });
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (inspectionId) {
          setInspectionIdLocal(inspectionId);
          const { inspectionData, status } =
            await inspectionService.getInspection({
              inspectionId,
            });
          if (status?.code === 200) {
            setInspection(inspectionData);
            const { storeData } = await storeService.getStore({
              storeId: inspectionData.storeId,
            });
            setStore(storeData);
            const { logoData } = await clientService.getClientLogo({
              imageUrl: storeData.image_url,
            });
            setClientLogo(logoData);
          }
        }
      } catch (error) {
        if (error?.response?.status) {
          if (error.config.url !== "accounts/downloadFile") {
            //temporary: disabling redirection for download API
            navigate(SITE_URL.SERVICE_UNAVAILABLE, {
              state: {
                message:
                  ERROR_MESSAGE[error.response.status] ||
                  error.response?.data?.status?.message,
                buttonType: ERROR_MESSAGE.BUTTONS.OK.KEY,
                redirectTo: `/${
                  authHelper.getInspectionDetails().assigned_id
                }/${
                  authHelper.getInspectionDetails()._id
                }/${authHelper.getStoredAuthToken()}/inspection`,
              },
            });
          }
        }
        console.log("error", error);
      }
    })();
  }, [inspectionId]);

  useEffect(() => {
    if (!isObjEmpty(inspection) && !isObjEmpty(store)) {
      setIsDataLoaded(true);
    }
  }, [inspection, store]);

  return {
    inspection,
    store,
    clientLogo,
    isDataLoaded,
    acceptFraudNotice,
    setAcceptFraudNotice,
    hideHeaderFooter,
    setHideHeaderFooter,
    inspectionIdLocal,
  };
};

export { useApp };
