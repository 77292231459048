import { request } from "../../core/networkRequests";
import { METHOD_TYPES } from "../../../shared/constants/method-types";
import { ENDPOINTS } from "../../../shared/constants/endpoints";

export const sendSms = async (message, mobileNumber) => {
  const { data } = await request(METHOD_TYPES.POST, `${ENDPOINTS.SEND_SMS}`, {
    message: message,
    mobile: mobileNumber,
  });
  return {
    smsResponse: data.status,
  };
};
