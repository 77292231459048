import React from "react";

export default function idComponent(props) {
  return (
    <div className="column">
      <div className="sub-header leter-spacing">RO #{props.data.req_number}</div>
      <div className="claim-style">Claim #: {props.data.claim_number}</div>
    </div>
  );
}
