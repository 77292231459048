import { createContext } from "react";
import Modal from "../components/commonComponents/modal";
import { useModal } from "../hooks/useModal";

const ModalContext = createContext({
  pageType: "",
});

ModalContext.displayName = "Modal";

const ModalProvider = ({ children }) => {
  const {
    isOpen,
    header,
    content,
    footer,
    openModal,
    closeModal,
    version,
    disableBackDropClose,
    hideCloseIcon,
  } = useModal();
  const context = {
    isOpen,
    header,
    content,
    footer,
    openModal,
    closeModal,
    version,
    disableBackDropClose,
    hideCloseIcon,
  };
  return (
    <ModalContext.Provider value={context}>
      <Modal />
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
