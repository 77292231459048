import { Box, Typography } from "@mui/material";
import React from "react";
import { localText } from "../../../constants/text";
import "../../styleSheet/inspectionNotStarted.scss"

export default function CardWithId(props) {
  return (
    <>
      <Typography
        variant="h6"
        marginTop={2}
        fontWeight={"bold"}
        lineHeight={1.5}
        letterSpacing={1}
        fontSize={24}
      >
        {props.mainId}
      </Typography>
      <div className="div-center-row">
        <div className="sub-headers">
          {localText.vin}: {props.vinId.toLowerCase().replace("vin", "***")}
        </div>
        <div className="sub-headers">
          {props.vindDays}
        </div>
      </div>
      <div className="sub-headers">
        {localText.claim} #: {props.claimId}
      </div>
    </>
  );
}
