import React, { useContext } from "react";
import Slider from "react-slick";
import deleteImg from "../../../assets/images/delete.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slider.scss";
import { InspectionContext } from "../../../contexts/inspection.context";
import { ImageModalContext } from "../../../contexts/imageModal.context";
import MessageModal from "../messagemodal";
import { getAllSupportedMimeTypes, isArrayEmpty } from "../../../shared/helper";
import RenderFile from "../renderFile";
import { ModalContext } from "../../../contexts/modal.context";

export default function SliderComponent({ data, from }) {
  const settings = {
    dots: false,
    infinite: false,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const { deleteMediaFromState } = useContext(InspectionContext);
  const { openImageModal } = useContext(ImageModalContext);
  const { openModal, closeModal } = useContext(ModalContext);
  const [firstSupportedMimeType] = getAllSupportedMimeTypes();

  const deleteMedia = ({ fileName }) => {
    closeModal();
    deleteMediaFromState({ fileName, from });
  };

  const ImageDiv = (imageAr) => {
    return (
      <div>
        <img
          src={imageAr}
          alt="maximized"
          style={{ height: "80vh", width: "100%" }}
        />
      </div>
    );
  };

  const imageModal = (image) => {
    openImageModal({
      content: ImageDiv(image),
      disableBackDropClose: false,
    });
  };

  return (
    <div>
      <Slider {...settings}>
        {!isArrayEmpty(data) &&
          data.map((item, key) => (
            <div key={key} className="main-slider-wrapper">
              {item.type === "video" ? (
                <video controls className="slider-video-style">
                  <source src={item.local} type={firstSupportedMimeType} />
                </video>
              ) : (
                <div className="slider-image-style">
                  <RenderFile
                    fileUrl={item?.path}
                    className="main-img"
                    alt={item.file}
                  />
                </div>
              )}
              <img
                src={deleteImg}
                width={10}
                height={10}
                className="dlt-img"
                onClick={() => {
                  openModal({
                    content: (
                      <MessageModal
                        clickCancel={closeModal}
                        clickOk={() => deleteMedia({ fileName: item.filename })}
                      />
                    ),
                    disableBackDropClose: true,
                  });
                }}
                alt="delete"
              />
            </div>
          ))}
      </Slider>
    </div>
  );
}
