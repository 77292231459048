import splash from "../assets/images/bg_splash.png";
import capture from "../assets/images/capture.png";
import videoThumb from "../assets/images/video.png";
import switchCamera from "../assets/images/switch_camera.png";
import loader from "../assets/animations/loader.json";
import loaderSuccess from "../assets/animations/loader_success.json";
import loaderFail from "../assets/animations/loader_fail.json";
import addAsset from "../assets/images/add.png";

export const appImages = {
  splash,
  capture,
  videoThumb,
  switchCamera,
  addAsset
};

export const appAnimations = {
  loader,
  loaderSuccess,
  loaderFail,
};