const ButtonComponent = (props) => {
  const Button = () => {
    return (
      <button
        className={props.className}
        disabled={props.disabled}
        style={{ ...props.style }}
        onClick={props.onClick}
      >
        {props.title}
      </button>
    );
  };

  if (props.wrapper) return withWrapper(Button);
  return <Button />;
};

const withWrapper = (WrappedComponent) => {
  return (
    <div className="button-wrapper">
      <WrappedComponent />
    </div>
  );
};

ButtonComponent.defaultProps = {
  wrapper: true,
};

export default ButtonComponent;
