import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { localText } from "../../../constants/text";
import { styles } from "../../styleSheet";
import BaseImage from "../../commonComponents/basetoimage";

import IdComponent from "./idComponent";
import ErrorComponent from "./errorFlow";
import SuccessComponent from "./successFlow";
import { AppContext } from "../../../contexts/app.context";
import { sendSms } from "../../../services/functional/sms/smsService";
import { authHelper } from "../../../services/app";
import Loader from "../../commonComponents/loader/loader";
import PhoneNumberInput from "../../commonComponents/input";
import { useNavigate } from "react-router-dom";
import "../../styleSheet/sms.scss";
import {
  ERROR_MESSAGE,
  INSPECTION_STATUSES,
  SITE_URL,
} from "../../../shared/constants/constants";

export default function SignIn(props) {
  const { inspection, clientLogo, isDataLoaded } = useContext(AppContext);
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState("");
  const [status, setStatus] = useState("");
  const handleSubmit = async (event) => {
    setStatus("loading");
    const link =
      process.env.REACT_APP_MESSAGE_API_URL +
      inspection.assigned_id +
      "/" +
      inspection._id +
      "/" +
      authHelper.getStoredAuthToken() +
      "/inspection";
    const message =
      "Hello. We want to process the claim you submitted quickly, but need a few details first. \n\n" +
      link;
    try {
      let post = await sendSms(message, `+1${mobileNumber}`);
      if (post.smsResponse.code === 200) {
        setStatus("success");
      }
    } catch (error) {
      setStatus("error");
    }
  };

  const returnComponentBaseCondition = () => {
    if (status === "") {
      return <IdComponent data={inspection} />;
    } else if (status === "success") {
      return <SuccessComponent resend={handleSubmit} />;
    } else if (status === "loading") {
      return (
        <div style={{ height: "25rem" }}>
          <Loader />
        </div>
      );
    } else {
      return <ErrorComponent />;
    }
  };

  const fetchValue = (value) => {
    setMobileNumber(value);
  };

  useEffect(() => {
    if (isDataLoaded) {
      if (inspection.status === INSPECTION_STATUSES.SUBMITTED) {
        navigate(SITE_URL.SERVICE_UNAVAILABLE, {
          state: {
            title: ERROR_MESSAGE.LINK_INACTIVE,
            message: ERROR_MESSAGE.LINK_INACTIVE_MESSAGE,
            buttonType: ERROR_MESSAGE.BUTTONS.CLOSE.KEY,
          },
        });
      }
    } else {
      navigate(SITE_URL.SERVICE_UNAVAILABLE, {
        state: {
          title: ERROR_MESSAGE.CONTINUE_TO_REFRESH,
          message: ERROR_MESSAGE.RETURN_TO_LINK,
          buttonType: ERROR_MESSAGE.BUTTONS.BACK.KEY,
          redirectTo: `/${authHelper.getInspectionDetails().assigned_id}/${
            authHelper.getInspectionDetails()._id
          }/${authHelper.getStoredAuthToken()}/mobile`,
        },
      });
    }
  }, [isDataLoaded]);

  if (!isDataLoaded) return null;

  return (
    <div className="sms column">
      {Object.keys(clientLogo).length > 0 && (
        <BaseImage className="logo" baseString={clientLogo?.asset} />
      )}
      <div className="header">{localText.virtualInspection}</div>
      <>{returnComponentBaseCondition()}</>
      {(status === "" || status === "error") && (
        <Box
          className="column"
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 5 }}
        >
          <PhoneNumberInput fetchValue={fetchValue} />

          <Button
            onClick={handleSubmit}
            fullWidth
            disabled={mobileNumber.length != 10}
            style={styles.buttonStyle}
            sx={{
              mt: 5,
              mb: 2,
              opacity: mobileNumber.length != 10 ? "0.5" : "1",
            }}
          >
            Send
          </Button>
          <div className="data-rate-style">
            [<span>{localText.mobileDataRate}</span>
            <span class="tbd">{localText.tbd}</span>]
          </div>
        </Box>
      )}
    </div>
  );
}
