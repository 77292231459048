import { useContext } from "react";
import Modal from "@mui/material/Modal";
import { ImageModalContext } from "../../../contexts/imageModal.context";
import CloseIcon from "@mui/icons-material/Close";

export default function ImageModalComponent(props) {
  const { content, isOpen, closeImageModal } = useContext(ImageModalContext);

  return (
    <Modal
      open={isOpen}
      onClose={props.handleClose}
      onBackdropClick={closeImageModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="center-div"
    >
      <div style={{ margin: 10 }}>
        <div style={{ float: "right" }} onClick={closeImageModal}>
          <CloseIcon sx={{ color: "white" }} />
        </div>
        {content}
      </div>
    </Modal>
  );
}
