import { useContext, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Divider, Menu, MenuItem } from "@mui/material";
import { styles } from "../../styleSheet";
import "../stylesheet/header-footer.scss";
import useWindowDimensions from "../../../services/functional/useDimensions";
import Base64ToImage from "../basetoimage";
import { AppContext } from "../../../contexts/app.context";
import FraudNotice from "../../screens/inspection/fraudNotice";
import Faq from "../../screens/faq";
import { ModalContext } from "../../../contexts/modal.context";

export default function Header(props) {
  const { clientLogo: image, hideHeaderFooter } = useContext(AppContext);
  const { openModal } = useContext(ModalContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { width } = useWindowDimensions();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const showFaqModal = (e) => {
    e.preventDefault();
    openModal({ content: <Faq /> });
  };

  const showFraudNoticeModal = (e) => {
    e.preventDefault();
    openModal({
      content: <FraudNotice />,
      disableBackDropClose: true,
      hideCloseIcon: true,
    });
  };
  if (hideHeaderFooter) return null;
  return (
    <div className={width > 700 ? "header-wrapper" : "header-wrapper-mobile"}>
      <AppBar className="header">
        <Toolbar className="tool-bar">
          {Object.keys(image).length > 0 && (
            <Base64ToImage baseString={image.asset} className="logo-img" />
          )}
          {width > 700 ? (
            <Typography className="logo-text">Virtual Inspection</Typography>
          ) : (
            <></>
          )}
          <IconButton
            aria-label="menu"
            sx={{ mr: 1, marginRight: 0, paddingRight: 0 }}
            onClick={handleClick}
          >
            <MenuIcon className="menu-icon" color="black" />
          </IconButton>
        </Toolbar>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          style={{ backgroundColor: "rgb(0 0 0 / 77%)" }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              width: 200,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={showFaqModal} style={styles.menuItem}>
            Tutorial & FAQ
          </MenuItem>
          <Divider />
          <MenuItem onClick={showFraudNoticeModal} style={styles.menuItem}>
            Fraud Notice
          </MenuItem>
        </Menu>
      </AppBar>
    </div>
  );
}
