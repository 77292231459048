import Router from "./components/router";
import "./App.scss";
import "./components/commonComponents/stylesheet/styles.scss";
import "./components/screens/inspection/styles/styles.scss";
import "./components/styleSheet/styles.scss";
import 'typeface-open-sans';

function App() {
  return (
    <>
      <Router />
    </>
  );
}

export default App;
