import { localText } from "../../../../constants/text";
import "../../../styleSheet/submitReview.scss";

export default function Review(props) {
  return (
    <>
      <div className="submit-header">
        {localText.readyForSubmit.submitForReview}
      </div>
      <div className="submit-sub-header">
        {localText.readyForSubmit.ifYouReady}
        <span className="submit-header-style">
          {localText.readyForSubmit.submit}
        </span>
      </div>
    </>
  );
}
