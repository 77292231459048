import React from "react";
import Lottie from "lottie-react";
import { appAnimations } from "../../../../constants/image";
import { localText } from "../../../../constants/text";
import { SITE_URL } from "../../../../shared/constants/constants";

export default function Success() {
  return (
    <>
      <div className="submit-page-style">
        <div className="success-text">{localText.success}!</div>
        <Lottie
          className="animation"
          animationData={appAnimations.loaderSuccess}
          loop={false}
        />
        <div className="mt-20 mb-20 text-center">{localText.thankYou}</div>
        <a
          className={"button-contained"}
          style={{
            textAlign: "center",
            textDecoration: "none",
            fontWeight: "bold",
          }}
          target="_blank"
          rel="noreferrer"
          href={SITE_URL.ASSURANT_HOME_PAGE}
        >
          {localText.closePage}
        </a>
      </div>
    </>
  );
}
