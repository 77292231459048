import { useContext } from "react";
import { InspectionContext } from "../../../../contexts/inspection.context";
import { CONSTANTS } from "../../../../shared/constants/constants";
import CameraVideo from "./cameraVideo";
import Photo from "./photo";
import Video from "./video";

export default function Media() {
  const { subStepMode } = useContext(InspectionContext);

  return (
    <>
      {subStepMode === CONSTANTS.IMAGE ? (
        <Photo />
      ) : subStepMode === CONSTANTS.VIDEO ? (
        <Video />
      ) : (
        <CameraVideo from={CONSTANTS.REQUESTED_MEDIA} />
      )}
    </>
  );
}
