import { useContext, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import withInspectionProvider from "../../../hoc/withInspectionProvider";
import AppStepper from "../../commonComponents/stepper";
import Start from "./start";
import Requested from "./requested";
import Additional from "./additional";
import Review from "./review";
import { Box, Container } from "@mui/material";
import ButtonComponent from "../../commonComponents/button";
import {
  CONSTANTS,
  ERROR_MESSAGE,
  INSPECTION_STATUSES,
  SITE_URL,
  STEPS,
} from "../../../shared/constants/constants";
import CardWithId from "../../commonComponents/CardWithId";
import { styles } from "../../styleSheet";
import { AppContext } from "../../../contexts/app.context";
import { InspectionContext } from "../../../contexts/inspection.context";
import Confirmation from "./confirmation";
import Camera from "../../commonComponents/camera";
import Video from "../../commonComponents/video";
import FraudNotice from "./fraudNotice";
import { useNavigate } from "react-router-dom";
import { ModalContext } from "../../../contexts/modal.context";
import { readTimestamp, validateRequestedForm } from "../../../shared/helper";
import { INSPECTION_VARIANTS } from "../../../shared/constants/animations";
import { authHelper } from "../../../services/app";

function Inspection() {
  const { inspection, isDataLoaded } = useContext(AppContext);
  const { openModal } = useContext(ModalContext);
  const navigate = useNavigate();
  const {
    steps,
    sendInspection,
    cameraMode,
    subStepMode,
    activeStep,
    setActiveStep,
    inspectionToBeSent,
    showConfirmationScreen,
    activeStepName,
    animationDirection,
    setAnimationDirection,
  } = useContext(InspectionContext);

  useEffect(() => {
    if (isDataLoaded) {
      if (inspection.status === INSPECTION_STATUSES.SUBMITTED) {
        navigate(SITE_URL.SERVICE_UNAVAILABLE, {
          state: {
            title: ERROR_MESSAGE.LINK_INACTIVE,
            message: ERROR_MESSAGE.LINK_INACTIVE_MESSAGE,
            buttonType: ERROR_MESSAGE.BUTTONS.CLOSE.KEY,
          },
        });
      }
      openModal({
        content: <FraudNotice />,
        disableBackDropClose: true,
        hideCloseIcon: true,
      });
    } else {
      navigate(SITE_URL.SERVICE_UNAVAILABLE, {
        state: {
          title: ERROR_MESSAGE.CONTINUE_TO_REFRESH,
          message: ERROR_MESSAGE.RETURN_TO_LINK,
          buttonType: ERROR_MESSAGE.BUTTONS.BACK.KEY,
          redirectTo: `/${authHelper.getInspectionDetails().assigned_id}/${
            authHelper.getInspectionDetails()._id
          }/${authHelper.getStoredAuthToken()}/inspection`,
        },
      });
    }
  }, [isDataLoaded]);

  if (!isDataLoaded) return null;

  const getSectionComponent = () => {
    switch (activeStep) {
      case 1:
        return <Start />;
      case 2:
        return <Requested />;
      case 3:
        return <Additional />;
      case 4:
        return <Review />;
      default:
        return null;
    }
  };

  const handleSubmit = () => {
    setAnimationDirection(1);
    sendInspection();
  };

  const enableNextButton = () => {
    if (activeStepName === STEPS.START || activeStepName === STEPS.ADDITIONAL) {
      return true;
    }
    if (activeStepName === STEPS.REQUESTED) {
      return validateRequestedForm({ inspection, inspectionToBeSent });
    }
    return false;
  };

  return (
    <AnimatePresence mode="wait" initial={false} custom={animationDirection}>
      {cameraMode === null ? (
        <div>
          <Container component="main" maxWidth="xs">
            {!showConfirmationScreen ? (
              <>
                <AppStepper activeStep={activeStep} />
                <motion.div
                  variants={INSPECTION_VARIANTS}
                  animate="animate"
                  initial="initial"
                  exit="exit"
                  alt="slides"
                  className="slides"
                  key={`${activeStep}-${subStepMode}-${cameraMode}`}
                  custom={animationDirection}
                >
                  <div className="loading-component">
                    <Box className="" sx={styles.parentSmsStyleInspections}>
                      {!subStepMode && (
                        <CardWithId
                          mainId={`RO #${inspection.req_number}`}
                          claimId={inspection.claim_number}
                          vinId={inspection.vin}
                          vindDays={readTimestamp(inspection.start_time)}
                        />
                      )}
                      {getSectionComponent()}
                      {!subStepMode && (
                        <div className="double-button-div">
                          {activeStep !== 1 && activeStep <= steps.length && (
                            <ButtonComponent
                              wrapper={false}
                              onClick={() => {
                                setAnimationDirection(-1);
                                setActiveStep(activeStep - 1);
                              }}
                              title={CONSTANTS.BACK}
                              className="btn-1"
                            />
                          )}
                          {activeStep !== steps.length && (
                            <ButtonComponent
                              wrapper={false}
                              onClick={() => {
                                setAnimationDirection(1);
                                setActiveStep(activeStep + 1);
                              }}
                              title={
                                activeStep === 3
                                  ? CONSTANTS.REVIEW
                                  : CONSTANTS.NEXT
                              }
                              className={
                                activeStep === 1 ? "next-button" : "btn-2"
                              }
                              disabled={!enableNextButton()}
                              style={{
                                opacity: enableNextButton()
                                  ? 1
                                  : CONSTANTS.DISABLED_OPACITY,
                              }}
                            />
                          )}
                          {activeStep === steps.length && (
                            <ButtonComponent
                              wrapper={false}
                              onClick={handleSubmit}
                              title={"Submit"}
                              className="btn-2"
                            />
                          )}
                        </div>
                      )}
                    </Box>
                  </div>
                </motion.div>
              </>
            ) : (
              <Confirmation />
            )}
          </Container>
        </div>
      ) : cameraMode === CONSTANTS.VIDEO ? (
        <Video />
      ) : (
        <Camera />
      )}
    </AnimatePresence>
  );
}

export default withInspectionProvider(Inspection);
