import { Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ModalContext } from "../../contexts/modal.context";
import { ERROR_MESSAGE, SITE_URL } from "../../shared/constants/constants";
import "../styleSheet/modals.scss";

export default function ServiceUnavailable() {
  const { openModal, closeModal } = useContext(ModalContext);
  const { state } = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    openModal({
      content: (
        <>
          <Typography
            id="modal-modal-title"
            variant="h6"
            color={"black"}
            fontWeight={600}
          >
            {state?.title || ERROR_MESSAGE.OOPS}
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, color: "#3D515A" }}
          >
            {state?.message || ERROR_MESSAGE.SERVICE_UNAVAILABLE}
          </Typography>
          {renderButton()}
        </>
      ),
      disableBackDropClose: true,
      hideCloseIcon: true,
      version: 2,
    });
  }, []);

  const renderButton = () => {
    if (state?.buttonType === undefined) return;
    let data = {};
    switch (state.buttonType) {
      case ERROR_MESSAGE.BUTTONS.OK.KEY:
        data = {
          text: ERROR_MESSAGE.BUTTONS.OK.TEXT,
          handleClick: () => {
            closeModal();
            navigate(state?.redirectTo);
          },
        };
        break;
      case ERROR_MESSAGE.BUTTONS.CLOSE.KEY:
        data = {
          text: ERROR_MESSAGE.BUTTONS.CLOSE.TEXT,
          handleClick: () => {
            closeModal();
            window.location.href = SITE_URL.ASSURANT_HOME_PAGE;
          },
        };
        break;
      case ERROR_MESSAGE.BUTTONS.BACK.KEY:
        data = {
          text: ERROR_MESSAGE.BUTTONS.BACK.TEXT,
          handleClick: () => {
            closeModal();
            navigate(state?.redirectTo);
          },
        };
        break;
      default:
        break;
    }

    return (
      <button onClick={data.handleClick} className="error-popup">
        {data.text}
      </button>
    );
  };

  return <></>;
}
