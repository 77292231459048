import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React from "react";

export default function InspectionSmallHeader(props) {
  return (
    <>
      <div className="mt-10 inspection-header">
        <ArrowBackIcon
          className="back-icon"
          sx={{ color: "black" }}
          onClick={props.goBack}
        />
        <div>{props.title} </div>
      </div>
    </>
  );
}
