import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../contexts/app.context";
import { isObjEmpty } from "../../../shared/helper";
import { appImages } from "../../../constants/image";

import "../../styleSheet/splash.scss";

export default function SplashScreen({ redirectTo }) {
  const navigate = useNavigate();
  const { inspection, store } = useContext(AppContext);

  useEffect(() => {
    if (!isObjEmpty(inspection) && !isObjEmpty(store)) {
      navigate(redirectTo);
    }
  }, [inspection, store, redirectTo, navigate]);

  return (
    <div className="container">
      <img src={appImages.splash} className="image" alt="splash" />
    </div>
  );
}
