import React from "react";
import Lottie from "lottie-react";
import success from "../../../assets/animations/loader_success.json";
import ButtonComponent from "../../commonComponents/button";
import { localText } from "../../../constants/text";

export default function SuccessFlow(props) {
  return (
    <div className="column">
      <div className="sub-header">{localText.success}</div>
      <div className="claim-style">{localText.checkMobile}</div>
      <Lottie className="animation" animationData={success} loop={false} />
      <ButtonComponent
        title="Resend"
        onClick={props.resend}
        className="outlined-button"
      />
    </div>
  );
}
