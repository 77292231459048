import { createContext } from "react";
import { useInspection } from "../hooks/useInspection";

const InspectionContext = createContext({});

InspectionContext.displayName = "Inspection";

const InspectionProvider = ({ children }) => {
  const {
    steps,
    activeStep,
    setActiveStep,
    activeStepName,
    feedData,
    selectedNameForMedia,
    setSelectedNameForMedia,
    inspectionToBeSent,
    deleteMediaFromState,
    sendInspection,
    imageVideoUpload,
    cameraMode,
    setCameraMode,
    subStepMode,
    setSubStepMode,
    instructionsContent,
    setInstructionsContent,
    postInspectionData,
    showConfirmationScreen,
    animationDirection,
    setAnimationDirection,
  } = useInspection();
  const context = {
    steps,
    activeStep,
    setActiveStep,
    activeStepName,
    feedData,
    selectedNameForMedia,
    setSelectedNameForMedia,
    inspectionToBeSent,
    deleteMediaFromState,
    sendInspection,
    imageVideoUpload,
    cameraMode,
    setCameraMode,
    subStepMode,
    setSubStepMode,
    instructionsContent,
    setInstructionsContent,
    postInspectionData,
    showConfirmationScreen,
    animationDirection,
    setAnimationDirection,
  };

  return (
    <InspectionContext.Provider value={context}>
      {children}
    </InspectionContext.Provider>
  );
};

export { InspectionContext, InspectionProvider };
