import { useState } from "react";
import { localText } from "../../../../constants/text";
import VideocamIcon from "@mui/icons-material/Videocam";
import TutorialVideo from "../../../commonComponents/tutorialVideo";

export default function Start(props) {
  const [openVideoTutorial, setopenVideoTutorial] = useState(false);

  const closeVideoTutorial = () => {
    setopenVideoTutorial(false);
  };
  return (
    <>
      <div className="explanation-div">
        <div className="font-bold">{localText.howitWorks}</div>
        <div
          className="tutorial-btn"
          onClick={() => setopenVideoTutorial(true)}
        >
          <VideocamIcon color="primary" />
          <div>{localText.tutorial}</div>
        </div>
      </div>
      <div className="explanation">{localText.notStarted.useYourDevice}</div>
      <div className="explanation">{localText.notStarted.toReduce}</div>
      <div className="explanation">{localText.notStarted.youWillAble}</div>
      <TutorialVideo
        isOpen={openVideoTutorial}
        handleClose={closeVideoTutorial}
      />
    </>
  );
}
