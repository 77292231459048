import React from "react";
import Lottie from "lottie-react";
import { appAnimations } from "../../../constants/image";
import { localText } from "../../../constants/text";

export default function errorFlow() {
  return (
    <div className="column">
      <Lottie
        className="animation"
        animationData={appAnimations.loaderFail}
        loop={false}
      />
      <div className="sub-header">{localText.unableToSend}</div>
      <div className="claim-style">{localText.tryAgain}</div>
    </div>
  );
}
