import { isValidElement, useState } from "react";

const useImageModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState("");
  const [disableBackDropClose, setDisableBackDropClose] = useState(false);

  const openImageModal = ({ content }) => {
    setIsOpen(true);
    setDisableBackDropClose(true);
    if (isValidElement(content)) {
      setContent(content);
    }
  };

  const closeImageModal = () => {
    setIsOpen(false);
  };

  return {
    isOpen,
    content,
    openImageModal,
    closeImageModal,
    disableBackDropClose,
  };
};

export { useImageModal };
