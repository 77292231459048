import { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import MakePortal from "../../../contexts/make.portal";
import { ModalContext } from "../../../contexts/modal.context";
import useWindowDimensions from "../../../services/functional/useDimensions";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: [4, 3],
  outline: "none",
  borderRadius: 2,
};

export default function ModalComponent(props) {
  const {
    content,
    isOpen,
    closeModal,
    version,
    disableBackDropClose,
    hideCloseIcon,
  } = useContext(ModalContext);
  const [classes, setClasses] = useState({});
  const { width } = useWindowDimensions();

  /**
   * Pass version from individual components and render unique classes and write unique CSS accordingly.
   * Create as many different variations as needed.
   */
  useEffect(() => {
    switch (version) {
      case 1:
        //popups like image view, video view.
        setClasses({
          modal: "image-modal",
          box: "image-modal-wrapper",
          closeIcon: "image-close",
          content: "v1",
        });
        break;
      case 2:
        //popups like server errors on page refresh, or link has expired popup.
        setClasses({
          modal: "message-modal",
          box: "message-modal-wrapper",
          closeIcon: "message-close",
          content: "v2",
        });
        break;
      default:
        //popups like fraud notice, faqs.
        setClasses({
          modal: "faq-fraud-modal",
          box: "faq-fraud-modal-wrapper",
          closeIcon: "faq-fraud-close",
          content: "vDefault",
        });
        break;
    }
  }, [version]);

  return (
    <MakePortal selector="#root-modal">
      <Modal
        open={isOpen}
        onClose={props.handleClose}
        onBackdropClick={!disableBackDropClose ? closeModal : null}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={`center-div ${classes?.modal}`}
      >
        <Box
          sx={style}
          width={width < 500 ? 290 : 375}
          className={`modal-box ${classes?.box}`.trim()}
        >
          <div
            style={{ float: "right" }}
            onClick={closeModal}
            className={`modal-close-icon ${classes?.closeIcon}`.trim()}
          >
            {!hideCloseIcon && <CloseIcon sx={{ color: "black" }} />}
          </div>
          <div className={`modal-content ${classes?.content}`.trim()}>
            {content}
          </div>
        </Box>
      </Modal>
    </MakePortal>
  );
}
