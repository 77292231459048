import { request } from "../../core/networkRequests";
import { METHOD_TYPES } from "../../../shared/constants/method-types";
import { ENDPOINTS } from "../../../shared/constants/endpoints";

export const getStore = async ({ storeId }) => {
  const { data } = await request(
    METHOD_TYPES.POST,
    `${ENDPOINTS.GET_SINGLE_STORE}`,
    {
      storeId,
    }
  );
  return {
    storeData: data.data,
  };
};
