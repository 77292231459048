import { Phone } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

function PhoneNumberInput(props) {
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    const isNumericInput = (event) => {
      const key = event.keyCode;
      return (
        (key >= 48 && key <= 57) || // Allow number line
        (key >= 96 && key <= 105) // Allow number pad
      );
    };

    const isModifierKey = (event) => {
      const key = event.keyCode;
      return (
        event.shiftKey === true ||
        key === 35 ||
        key === 36 || // Allow Shift, Home, End
        key === 8 ||
        key === 9 ||
        key === 13 ||
        key === 46 || // Allow Backspace, Tab, Enter, Delete
        (key > 36 && key < 41) || // Allow left, up, right, down
        ((event.ctrlKey === true || event.metaKey === true) &&
          (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)) // Allow Ctrl/Command + A,C,V,X,Z
      );
    };

    const enforceFormat = (event) => {
      // Input must be of a valid number format or a modifier key, and not longer than ten digits
      if (!isNumericInput(event) && !isModifierKey(event)) {
        event.preventDefault();
      }
    };

    const formatToPhone = (event) => {
      if (isModifierKey(event)) {
        return;
      }

      const input = event.target.value.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
      const zip = input.substring(0, 3);
      const middle = input.substring(3, 6);
      const last = input.substring(6, 10);

      let formattedPhoneNumber = "";

      if (input.length > 6) {
        formattedPhoneNumber = `(${zip}) ${middle} - ${last}`;
      } else if (input.length > 3) {
        formattedPhoneNumber = `(${zip}) ${middle}`;
      } else if (input.length > 0) {
        formattedPhoneNumber = `(${zip}`;
      }

      setPhoneNumber(formattedPhoneNumber);
    };

    const inputElement = document.getElementById("phoneNumber");
    inputElement.addEventListener("keydown", enforceFormat);
    inputElement.addEventListener("keyup", formatToPhone);

    return () => {
      inputElement.removeEventListener("keydown", enforceFormat);
      inputElement.removeEventListener("keyup", formatToPhone);
    };
  }, []);

  const handleChange = (event) => {
    if (event.target.value.replace(/\D/g, "").length <= 10) {
      setPhoneNumber(event.target.value);
      props.fetchValue(event.target.value.replace(/\D/g, ""));
    }
  };

  return (
    <TextField
      id="phoneNumber"
      placeholder="Mobile number"
      label="Mobile number"
      fullWidth
      InputProps={{
        inputMode: "numeric",
        pattern: "[0-9]*",
        startAdornment: (
          <InputAdornment position="start">
            <Phone />
          </InputAdornment>
        ),
      }}
      value={phoneNumber}
      onChange={handleChange}
      variant="standard"
    />
  );
}

export default PhoneNumberInput;
