import { useCallback, useState } from "react";

const useLoader = () => {
  const [isLoading, setIsLoading] = useState(false);

  const setLoader = useCallback((loading) => {
    setIsLoading(loading);
  }, []);

  return { isLoading, setLoader };
};

export { useLoader };
