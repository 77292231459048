import { InspectionProvider } from "../contexts/inspection.context";

export default function withInspectionProvider(WrappedComponent) {
  const hocComponent = ({ ...props }) => (
    <InspectionProvider>
      <WrappedComponent {...props} />
    </InspectionProvider>
  );

  hocComponent.propTypes = {};

  return hocComponent;
}
