import { useContext } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Close } from "@mui/icons-material";

const style = {
  position: "absolute",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  width: 240,
};

export default function TutorialVideo(props) {
  return (
    <Modal
      open={props.isOpen}
      onClose={props.handleClose}
      onBackdropClick={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="center-div"
    >
      <div style={{ width: "80%", height: "80%" }}>
        <div style={{ float: "right" }}>
          <Close style={{ color: "white" }} onClick={props.handleClose} />
        </div>
        <iframe
          src="https://player.vimeo.com/video/812791879?h=da9a7d708d"
          style={{
            width: "100%",
            height: "100%",
            outline: "none",
            border: "none",
          }}
          title="Tutorial video"
        />
      </div>
    </Modal>
  );
}
