import { TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ButtonComponent from "../../../commonComponents/button";
import InspectionSmallHeader from "../../../commonComponents/inspectionHeader";
import { localText } from "../../../../constants/text";
import { InspectionContext } from "../../../../contexts/inspection.context";

export default function Comment(props) {
  const [comment, setComment] = useState("");
  const { feedData } = useContext(InspectionContext);

  useEffect(() => {
    setComment(props.comment);
  }, []);

  return (
    <div className="comment-wrapper">
      <InspectionSmallHeader
        title={localText.additionalComments}
        goBack={props.goBack}
      />
      <TextField
        id="standard-multiline-static"
        multiline
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        rows={8}
        placeholder={localText.moreDetails}
        variant="standard"
      />
      <ButtonComponent
        className="button-contained"
        title="Save"
        disabled={comment === ""}
        style={{ opacity: comment === "" ? 0.5 : 1 }}
        onClick={() => {
          feedData(localText.comment, comment);
          props.goBack();
        }}
      />
    </div>
  );
}
