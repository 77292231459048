import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import Webcam from "react-webcam";
import { AppContext } from "../../../contexts/app.context";
import captureIcon from "../../../assets/images/capture.png";
import switchCamera from "../../../assets/images/switch_camera.png";
import { localText } from "../../../constants/text";
import { InspectionContext } from "../../../contexts/inspection.context";
import { LoaderContext } from "../../../contexts/loader.context";

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const videoConstraints = {
  facingMode: FACING_MODE_USER,
};
const WebCamImage = (props) => {
  const [picture, setPicture] = useState("");
  const { setHideHeaderFooter, inspection } = useContext(AppContext);
  const { setCameraMode, imageVideoUpload } = useContext(InspectionContext);
  const { setLoader } = useContext(LoaderContext);
  const [facingMode, setFacingMode] = React.useState(FACING_MODE_ENVIRONMENT);

  const webcamRef = React.useRef(null);

  useEffect(() => {
    setLoader(true);
  }, []);

  const capture = React.useCallback(() => {
    const pictureSrc = webcamRef.current.getScreenshot();
    setPicture(pictureSrc);
  }, []);

  const switchCameraFunc = React.useCallback(() => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER
    );
  }, []);

  const postMediaFunc = async () => {
    imageVideoUpload({
      file: picture,
      inspectionRequestNumber: inspection.req_number,
      mediaType: "png",
    });
  };

  const handleUserMedia = (stream) => {
    if (stream.active) {
      setLoader(false);
    }
  };

  return (
    <>
      <div className="container-webcam">
        <div className="video-wrapper">
          {picture === "" ? (
            <>
              <Webcam
                ref={webcamRef}
                muted={true}
                className="video-component"
                screenshotFormat="image/png"
                videoConstraints={{
                  ...videoConstraints,
                  facingMode,
                }}
                onUserMedia={handleUserMedia}
              />
            </>
          ) : (
            <img src={picture} className="video-component" alt="img" />
          )}
        </div>
        <div>
          {picture === "" ? (
            <div className="camera-button-wrapper">
              <div>
                <button
                  onClick={() => {
                    setCameraMode(null);
                    setHideHeaderFooter(false);
                  }}
                >
                  {localText.cancel}
                </button>
              </div>
              <div className="capture-icon">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    capture();
                  }}
                >
                  <img src={captureIcon} alt="Capture" />
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    switchCameraFunc();
                  }}
                >
                  <img src={switchCamera} alt="Switch camera" />
                </button>
              </div>
            </div>
          ) : (
            <div className="retake-button">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setPicture("");
                }}
                className="retake-btn"
              >
                {localText.retake}
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  postMediaFunc();
                }}
                className="use-photo-btn"
              >
                {localText.usePhoto}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default WebCamImage;
