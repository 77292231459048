import React, { useContext, useRef, useState, useEffect } from "react";
import Webcam from "react-webcam";
import { localText } from "../../../constants/text";
import stopIcon from "../../../assets/images/videoStop.png";
import startIcon from "../../../assets/images/videoStart.png";
import switchCamera from "../../../assets/images/switch_camera.png";
import { AppContext } from "../../../contexts/app.context";
import { InspectionContext } from "../../../contexts/inspection.context";
import { ModalContext } from "../../../contexts/modal.context";
import { getAllSupportedMimeTypes } from "../../../shared/helper";
import { ERROR_MESSAGE } from "../../../shared/constants/constants";
import { Alert, AlertTitle } from "@mui/material";
import { LoaderContext } from "../../../contexts/loader.context";

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const videoConstraints = {
  facingMode: FACING_MODE_USER,
};
const WebCamVideo = () => {
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [retakeUseBtn, showRetakeUseBtn] = useState(false);
  const { inspection, setHideHeaderFooter } = useContext(AppContext);
  const { setCameraMode, imageVideoUpload } = useContext(InspectionContext);
  const { setLoader } = useContext(LoaderContext);
  const { openModal } = useContext(ModalContext);
  const [timer, setTimer] = useState(0);
  const [start, setStart] = useState(false);
  const firstStart = useRef(true);
  const tick = useRef();
  const [facingMode, setFacingMode] = React.useState(FACING_MODE_ENVIRONMENT);
  const [options, setOptions] = useState({});
  const toggleStart = () => {
    setStart(!start);
  };

  useEffect(() => {
    setLoader(true);
  }, []);

  useEffect(() => {
    const [firstSupportedMimeType] = getAllSupportedMimeTypes();
    if (firstSupportedMimeType) {
      setOptions({ mimeType: firstSupportedMimeType });
    } else {
      setCameraMode(null);
      setHideHeaderFooter(false);
      openModal({
        content: (
          <>
            <Alert severity="error">
              <AlertTitle>{ERROR_MESSAGE.TEXT}</AlertTitle>
              {ERROR_MESSAGE.NO_MIME_TYPE_FOUND}
            </Alert>
          </>
        ),
      });
    }
  }, [openModal, setCameraMode, setHideHeaderFooter]);

  useEffect(() => {
    if (firstStart.current) {
      firstStart.current = !firstStart.current;
      return;
    }

    if (start) {
      tick.current = setInterval(() => {
        setTimer((timer) => timer + 1);
      }, 1000);
    } else {
      clearInterval(tick.current);
    }
    return () => clearInterval(tick.current);
  }, [start]);

  const dispSecondsAsMins = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const seconds_ = seconds % 60;
    return (
      mins.toString() + ":" + (seconds_ === 0 ? "00" : seconds_.toString())
    );
  };

  const handleStartRecording = () => {
    toggleStart();
    mediaRecorderRef.current = new MediaRecorder(
      webcamRef.current.stream,
      options
    );

    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
    setIsRecording(true);
  };

  const handleStopRecording = () => {
    toggleStart();
    setTimer(0);
    mediaRecorderRef.current.stop();
    console.log(webcamRef, "ref");
    // let stream = webcamRef.video.srcObject;
    // const tracks = stream.getTracks();

    // tracks.forEach((track) => track.stop());
    // webcamRef.video.srcObject = null;
    setIsRecording(false);
    showRetakeUseBtn(true);
  };

  const handleDataAvailable = (event) => {
    if (event.data.size > 0) {
      setRecordedChunks((prev) => [...prev, event.data]);
    }
  };

  const switchCameraFunc = React.useCallback(() => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER
    );
  }, []);

  const postMediaFunc = async () => {
    imageVideoUpload({
      file: recordedChunks,
      inspectionRequestNumber: inspection.req_number,
      mediaType: "video",
    });
  };

  const handleUserMedia = (stream) => {
    if (stream.active) {
      setLoader(false);
    }
  };

  return (
    <>
      <div className="container-webcam">
        <div className="video-wrapper">
          {!retakeUseBtn ? (
            <>
              <div className="timer-style">{dispSecondsAsMins(timer)}</div>
              <Webcam
                ref={webcamRef}
                muted={true}
                screenshotFormat="video/mp4"
                className="video-component"
                videoConstraints={{
                  ...videoConstraints,
                  facingMode,
                }}
                onUserMedia={handleUserMedia}
              />
            </>
          ) : (
            <div>
              {recordedChunks.length > 0 && (
                <video className="video-component" controls>
                  <source
                    src={URL.createObjectURL(new Blob(recordedChunks))}
                    type={options.mimeType}
                  />
                </video>
              )}
            </div>
          )}
        </div>
        <div>
          {!retakeUseBtn ? (
            <div className="camera-button-wrapper">
              <div>
                <button
                  onClick={() => {
                    setCameraMode(null);
                    setHideHeaderFooter(false);
                  }}
                >
                  {localText.cancel}
                </button>
              </div>
              <div className="capture-icon">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    !isRecording
                      ? handleStartRecording()
                      : handleStopRecording();
                  }}
                >
                  {!isRecording ? (
                    <img
                      src={startIcon}
                      className="img-dimension"
                      alt="Start video recording"
                    />
                  ) : (
                    <img
                      src={stopIcon}
                      className="img-dimension"
                      alt="Stop video recording"
                    />
                  )}
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    switchCameraFunc();
                  }}
                >
                  <img src={switchCamera} alt={localText.switchCamera} />
                </button>
              </div>
            </div>
          ) : (
            <div className="retake-button">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setRecordedChunks([]);
                  showRetakeUseBtn(false);
                }}
                className="retake-btn"
              >
                {localText.retake}
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  postMediaFunc();
                }}
                className="use-photo-btn"
              >
                {localText.useVideo}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default WebCamVideo;
