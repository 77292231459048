import React, { useContext } from "react";
import secureLogo from "../../../assets/images/secure.png";
import { ModalContext } from "../../../contexts/modal.context";
import useWindowDimensions from "../../../services/functional/useDimensions";
import Faq from "../../screens/faq";
import FraudNotice from "../../screens/inspection/fraudNotice";
import "../stylesheet/header-footer.scss";
import { AppContext } from "../../../contexts/app.context";
import {
  CONSTANTS,
  FRAUD_NOTICE_LABELS,
  SITE_URL,
} from "../../../shared/constants/constants";

export default function Footer(props) {
  const { openModal } = useContext(ModalContext);
  const { hideHeaderFooter } = useContext(AppContext);
  const { width } = useWindowDimensions();

  const showFaqModal = (e) => {
    e.preventDefault();
    openModal({ content: <Faq /> });
  };

  const showFraudNoticeModal = (e) => {
    e.preventDefault();
    openModal({
      content: <FraudNotice />,
      disableBackDropClose: true,
      hideCloseIcon: true,
    });
  };

  let currentYear = new Date().getFullYear();

  const list = () => {
    return (
      <>
        <div className="footer-links">
          <button className="footer-btn font-small" onClick={showFaqModal}>
            {CONSTANTS.TUTORIALS_AND_FAQ}
          </button>
          <button
            className="footer-btn font-small"
            onClick={showFraudNoticeModal}
          >
            {FRAUD_NOTICE_LABELS.TITLE}
          </button>
          <a
            href={SITE_URL.TERMS_AND_CONDITIONS}
            target="_blank"
            rel="noreferrer"
            className=" footer-btn font-small"
          >
            {CONSTANTS.TERMS_AND_CONDITIONS}
          </a>
          <a
            href={SITE_URL.PRIVACY_POLICY}
            target="_blank"
            rel="noreferrer"
            className="footer-btn font-small"
          >
            {CONSTANTS.PRIVACY_POLICY}
          </a>
        </div>
      </>
    );
  };
  if (hideHeaderFooter) return null;
  return (
    <>
      {width > 700 ? (
        <div className="main-footer">
          <div style={{ display: "flex", justifyContent: "right", margin: 5 }}>
            <img alt="logo" src={secureLogo} className="footer-img" />
          </div>
          <div className="links-wrapper">
            <div style={{ fontSize: 12 }}>
              @{currentYear} {CONSTANTS.ALL_RIGHTS_RESERVED}
            </div>
            <div className="links">{list()}</div>
          </div>
        </div>
      ) : (
        <>
          <div className="main-footer-column">
            <div>
              <img alt="logo" src={secureLogo} className="footer-img" />
            </div>
            <div className="links-wrapper">
              <div style={{ marginLeft: 20, fontSize: 12, marginTop: 15 }}>
                @{currentYear} {CONSTANTS.ALL_RIGHTS_RESERVED}
              </div>
              <div className="links">{list()}</div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
