import { Outlet } from "react-router-dom";
import { AppProvider } from "../../../contexts/app.context";
import { LoaderProvider } from "../../../contexts/loader.context";
import { ModalProvider } from "../../../contexts/modal.context";
import { ModalImageProvider } from "../../../contexts/imageModal.context";

export default function RootLayout() {
  return (
    <>
      <AppProvider>
        <LoaderProvider>
          <ModalProvider>
            <ModalImageProvider>
              <Outlet />
            </ModalImageProvider>
          </ModalProvider>
        </LoaderProvider>
      </AppProvider>
    </>
  );
}
